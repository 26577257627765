import http from '@/utils/http/index'

// 获取二维码信息
export function getQrCodeInfo(id) {
  return http.get(`/checkout/qrcode/information/${id}`)
}

// 二维码支付结果
export function getQrCodeResult(id) {
  return http.get(`/checkout/qrcode/result/${id}`)
}

// 刷新二维码支付
export function getRefreshQrCode(id) {
  return http.get(`/checkout/qrcode/refresh/${id}`)
}
