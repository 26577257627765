<template>
  <a-spin :spinning="spinning">
    <template #indicator>
      <Loading></Loading>
    </template>
    <div class="mobile">
      <template v-if="!orderDetails?.checkoutLogo">
        <Lang></Lang>
        <div class="title">{{ $t('message.checkout.title') }}</div>
        <div class="description">{{ $t('message.checkout.description') }}</div>
      </template>
      <div v-else class="title-lang"><img class="title-logo" :src="orderDetails?.checkoutLogo" alt="LOGO" /> <Lang></Lang></div>
      <template>
        <div class="methods-wrapper methods">
          <div class="method-title">
            {{ $t('message.checkout.recommendation') }}
          </div>
          <div class="items" v-if="canSelectMethods && canSelectMethods.length > 0">
            <div
              @click="chooseMethod(topMethodsIndex[index], topMethods[index])"
              class="item"
              v-for="(item, index) in topMethods"
              :key="index"
              :class="{
                active: topMethodsIndex[index] === choosedPaymentMethodIndex
              }"
            >
              <img v-if="item?.cardName === 'CARD'" :src="item.logo" />
              <img v-else :src="`data:image/png;base64,${item.logo}`" />
              <div class="name" v-if="item?.cardName === 'CARD'">Credit/Debit Card</div>
              <div class="name" v-else>{{ item?.cardName }}</div>
            </div>
            <div v-if="canSelectMethods && canSelectMethods.length > 3" class="more" @click="viewAllMethod">...</div>
          </div>
          <div v-else class="description error-tips">
            {{ $t('message.checkout.methodErrorTips') }}
          </div>
        </div>
        <!-- <a-button
          v-if="canSelectMethods && canSelectMethods.length > 0"
          @click="viewAllMethod"
          class="more-methods"
          type="link"
        >
          {{ $t('message.checkout.otherPaymentMethods') }}
        </a-button>
        <div v-else class="description error-tips">
          {{ $t('message.checkout.methodErrorTips') }}
        </div> -->

        <div v-if="choosedPaymentMethod" class="warn-tips">{{ choosedPaymentMethod?.hint }}</div>
      </template>
      <div class="form-wrapper">
        <div class="countdown-wrapper">
          <div class="cards" v-if="canSelectMethods[choosedPaymentMethodIndex]?.cardName === 'CARD'">
            <img
              v-for="(card, cardIndex) in orderDetails.creditCardLogos"
              :key="cardIndex"
              :src="`data:image/png;base64,${card.logo}`"
            />
          </div>
          <div class="local" v-else>
            <img
              v-if="canSelectMethods[choosedPaymentMethodIndex]?.logo"
              :src="`data:image/png;base64,${canSelectMethods[choosedPaymentMethodIndex].logo}`"
            />
            <span>{{ canSelectMethods[choosedPaymentMethodIndex]?.cardName }}</span>
          </div>
          <div class="countdown">
            <span class="time">{{ initSecondDisplay(Math.floor(countdown / 60)) }}</span> :
            <span class="time">{{ initSecondDisplay(countdown % 60) }}</span>
          </div>
        </div>
        <div class="order-info">
          <div class="order-info-content">
            <div class="item">
              <span class="label">{{ $t('message.checkout.orderAmount') }}</span>
              <span class="text amount">{{ orderDetails.amount }} {{ orderDetails.currency }}</span>
            </div>
            <div class="item">
              <span class="label">{{ $t('message.checkout.orderId') }}</span>
              <span class="text">{{ orderDetails.uniqueId }}</span>
            </div>
            <div class="item">
              <span class="label">{{ $t('message.checkout.billingDescriptor') }}</span>
              <span class="text">{{ orderDetails.billingDescription }}</span>
            </div>
          </div>
        </div>
        <a-form-model :model="form" :colon="false" :rules="formRules" ref="formRef">
          <div class="payment-info" v-if="canSelectMethods[choosedPaymentMethodIndex].cardName === 'CARD'">
            <a-form-model-item prop="cardNumber">
              <template #label>
                <div class="card-label">
                  <span>{{ $t('message.checkout.cardNumber') }}</span>
                  <div class="card-logos">
                    <img
                      class="card-logo"
                      v-for="(card, cardIndex) in orderDetails.creditCardLogos"
                      :key="cardIndex"
                      :src="`data:image/png;base64,${card.logo}`"
                    />
                  </div>
                </div>
              </template>
              <cardnumber
                :allow-clear="allowClear"
                :cards="orderDetails.creditCardLogos"
                @cardName="onInputCardChange"
                @focus="onItemFocus()"
                @blur="onItemBlur('cardNumber', form.cardNumber.length)"
                :value.sync="form.cardNumber"
              ></cardnumber>
            </a-form-model-item>
            <a-row :gutter="9">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.cardName')" prop="cardFirstName">
                  <a-input
                    v-model="form.cardFirstName"
                    :size="size"
                    :allow-clear="allowClear"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('cardFirstName', form.cardFirstName.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="' '" prop="cardLastName" class="no-required">
                  <a-input
                    v-model="form.cardLastName"
                    :size="size"
                    :allow-clear="allowClear"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('cardLastName', form.cardLastName.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :label="$t('message.checkout.cvv')" prop="cvv">
              <a-input
                v-model="form.cvv"
                :size="size"
                :allow-clear="allowClear"
                inputmode="numeric"
                :maxLength="4"
                @input.native="cvvChangeHandler"
                @focus="onItemFocus()"
                @blur="onItemBlur('cvv', form.cvv.length)"
              >
                <template #suffix>
                  <img class="cvv-img" src="../../../../assets/images/checkout/cvv.png" />
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.expiredDate')" prop="expiredDate">
              <a-input
                v-model="form.expiredDate"
                :size="size"
                :maxLength="5"
                :allow-clear="allowClear"
                placeholder="MM/YY"
                autocomplete="off"
                @input.native="expiredDateChangeHandler"
                @focus="onItemFocus()"
                @blur="onItemBlur('expiredDate', form.expiredDate.length)"
              >
                <template #suffix>
                  <img class="expired-date-img" src="../../../../assets/images/checkout/expired.png" />
                </template>
              </a-input>
            </a-form-model-item>
          </div>
          <!--     bank 子项选择     -->
          <div class="payment-info" v-if="bankList && bankList.length > 0">
            <a-form-model-item
              :label="$t(getSubSelectMethodBackName(canSelectMethods[choosedPaymentMethodIndex]))"
              prop="extension.code"
            >
              <a-select
                v-model="form.extension.code"
                :size="size"
                :showSearch="true"
                :optionFilterProp="'label'"
                @change="onBankChange"
                @focus="onItemFocus()"
                @blur="onItemBlur('extension.code', form.extension.code, 'select')"
              >
                <a-select-option
                  v-for="(country, countryIndex) in bankList"
                  :key="countryIndex"
                  :value="country.code"
                  :label="country.name"
                >
                  {{ country.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div v-for="(item, index) in bankTemplateList" :key="index">
              <a-form-model-item :label="item?.title" :prop="`extension.${item?.param}`">
                <a-input
                  v-model="form.extension[item?.param]"
                  :size="size"
                  @focus="onItemFocus()"
                  @blur="onItemBlur(`extension.${item?.param}`, form.extension[item?.param].length)"
                />
              </a-form-model-item>
            </div>
          </div>
          <div class="billing-info">
            <div class="billing-title">
              {{ $t('message.checkout.billingInfo') }}
            </div>
            <a-row :gutter="9">
              <a-col v-if="displayItems.country" :span="!displayItems.state ? 24 : 12">
                <a-form-model-item :label="$t('message.checkout.country')" prop="country">
                  <a-select
                    v-model="form.country"
                    :size="size"
                    show-search
                    :filterOption="filterCountryOption"
                    @change="onCountryChange"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('country', form.country, 'select')"
                  >
                    <a-select-opt-group>
                      <template #label> </template>
                      <a-select-option
                        v-for="country in putTopCountryList"
                        :key="getCountryNameText(country, app)"
                        :value="country.code"
                        :label="getCountryNameText(country, app)"
                        :title="getCountryNameText(country, app)"
                      >
                        <template v-if="isShowEmoji()"> {{ country.emoji }} </template> {{ getCountryNameText(country, app) }}
                      </a-select-option>
                    </a-select-opt-group>
                    <a-select-opt-group>
                      <template #label v-if="putTopCountryList.length > 0">
                        <a-divider class="horizontal-divider"></a-divider>
                      </template>
                      <a-select-option
                        v-for="country in otherCountryList"
                        :key="getCountryNameText(country, app)"
                        :value="country.code"
                        :label="getCountryNameText(country, app)"
                        :title="getCountryNameText(country, app)"
                      >
                        <template v-if="isShowEmoji()"> {{ country.emoji }} </template> {{ getCountryNameText(country, app) }}
                      </a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-if="displayItems.state" :span="!displayItems.country ? 24 : 12">
                <a-form-model-item
                  v-if="stateList && stateList.length !== 0"
                  :label="$t('message.checkout.state')"
                  prop="state"
                >
                  <a-select
                    v-model="form.state"
                    :size="size"
                    show-search
                    :disabled="stateDisable"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('state', form.state, 'select')"
                  >
                    <a-select-option v-for="state in stateList" :key="state" :value="state" :label="state" :title="state">
                      {{ state }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item v-else :label="$t('message.checkout.state')" prop="state">
                  <a-input
                    v-model="form.state"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('state', form['state'].length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item v-if="displayItems.city" :label="$t('message.checkout.city')" prop="city">
              <a-input v-model="form.city" :size="size" @focus="onItemFocus()" @blur="onItemBlur('city', form.city.length)" />
            </a-form-model-item>
            <a-row :gutter="9">
              <a-col :span="displayItems.streetNumber ? 12 : 24" v-if="displayItems.address">
                <a-form-model-item :label="$t('message.checkout.address')" prop="address">
                  <a-input
                    v-model="form.address"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('address', form.address.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="displayItems.address ? 12 : 24" v-if="displayItems.streetNumber">
                <a-form-model-item :label="$t('message.checkout.streetNumber')" prop="streetNumber">
                  <a-input
                    v-model="form.streetNumber"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('streetNumber', form.streetNumber.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="9">
              <a-col :span="displayItems.lastName ? 12 : 24" v-if="displayItems.firstName">
                <a-form-model-item :label="$t('message.checkout.firstName')" prop="firstName">
                  <a-input
                    v-model="form.firstName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('firstName', form.firstName.length)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="displayItems.firstName ? 12 : 24" v-if="displayItems.lastName">
                <a-form-model-item :label="$t('message.checkout.lastName')" prop="lastName">
                  <a-input
                    v-model="form.lastName"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('lastName', form.lastName.length)"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item
              v-if="displayItems.document && refreshFormItem"
              :label="documentLabel || $t('message.checkout.document')"
              prop="document"
            >
              <a-input
                v-model="form.document"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('document', form.document.length)"
              />
            </a-form-model-item>
            <a-form-model-item v-if="displayItems.zipCode" :label="$t('message.checkout.zipCode')" prop="zipCode">
              <a-input
                v-model="form.zipCode"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('zipCode', form.zipCode.length)"
              />
            </a-form-model-item>
            <a-form-model-item v-if="displayItems.email" :label="$t('message.checkout.email')" prop="email">
              <a-input
                v-model="form.email"
                :size="size"
                @focus="onItemFocus()"
                @blur="onItemBlur('email', form.email.length)"
              />
            </a-form-model-item>
            <a-form-model-item v-if="displayItems.phone" :label="$t('message.checkout.phone')" prop="phone">
              <template v-if="!orderDetails?.billing?.phone?.includes('+')">
                <a-input-group compact>
                  <a-select
                    v-model="form.phoneCode"
                    :size="size"
                    show-search
                    @focus="onItemFocus()"
                    @blur="onItemBlur('phoneCode', form.phoneCode, 'select')"
                    style="width: 25%"
                  >
                    <a-select-option v-for="item in phoneCodeList" :key="item" :value="item" :label="item" :title="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                  <a-input
                    v-model="form.phone"
                    :size="size"
                    @focus="onItemFocus()"
                    @blur="onItemBlur('phone', form.phone.length)"
                    style="width: 75%"
                  >
                  </a-input> </a-input-group
              ></template>
              <template v-else>
                <a-input
                  v-model="form.phone"
                  :size="size"
                  @focus="onItemFocus()"
                  @blur="onItemBlur('phone', form.phone.length)"
                >
                </a-input>
              </template>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <div class="btns">
        <a-button class="button" type="primary" :size="size" @click="submit" :disabled="disable">
          <a-icon type="lock" theme="filled" />
          <span>{{ orderDetails.amount }} {{ $t('message.checkout.pay') }}</span>
        </a-button>
        <a-button v-if="orderDetails.cancelUrl" @click="cancel" class="return-link" type="link"
          >{{ $t('message.checkout.returnToMerchant') }}
        </a-button>
      </div>
      <a-drawer placement="bottom" :closable="false" :visible="viewMorePaymentMethod" :keyboard="false" :maskClosable="false">
        <template #title>
          <a-button @click="handleCancel" class="close" type="link">{{ $t('message.checkout.close') }}</a-button>
        </template>
        <div class="items">
          <div
            class="item"
            v-for="(method, methodIndex) in canSelectMethods"
            :key="methodIndex"
            @click="chooseMethod(methodIndex, method)"
            :class="{ active: activePaymentMethodIndex === methodIndex }"
          >
            <img v-if="method?.cardName === 'CARD'" class="method-img" :src="method.logo" />
            <img v-else class="method-img" :src="`data:image/png;base64,${method.logo}`" />
            <span v-if="method?.cardName === 'CARD'" class="method-name">Credit/Debit Card</span>
            <span v-else class="method-name">{{ method?.cardName }}</span>
          </div>
        </div>
      </a-drawer>
    </div>
  </a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import cardnumber from '@/components/cardnumber'
import Loading from '@/components/loading'
import { getCheckoutData, initFormData, onExpiredDateInput, onSecurityCodeInput } from '@/utils/tools/checkout'
import { confirm } from '@/views/checkout/api'
import Lang from '@/components/lang/index.vue'
import {
  getSubSelectMethodBackName,
  initBankList,
  initPhoneCode,
  initCanUseMethod,
  initCountryOptions,
  initExtensionRules,
  initRequiredFields,
  isFilterOption,
  getCountryNameText,
  showDocument,
  initSecondDisplay,
  isShowEmoji,
  stateControl,
  documentControl,
  toTransactionResult,
  getDefaultCountry,
  initTopMethodList
} from '@/views/checkout/tools'
import { saveExecuteTime, saveOperation, submitOperationData } from '@/views/checkout/tools/collect'

export default {
  components: { cardnumber, Loading, Lang },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['app'])
  },
  data() {
    return {
      refreshFormItem: true,
      phoneCodeList: [],
      stateList: [],
      stateDisable: false,
      putTopCountryList: [],
      otherCountryList: [],
      orderDetails: this.$props.order,
      canSelectMethods: [],
      size: 'large',
      documentLabel: '',
      allowClear: true,
      viewMorePaymentMethod: false,
      topMethods: [],
      topMethodsIndex: [],
      choosedPaymentMethod: null,
      choosedPaymentMethodIndex: 0,
      activePaymentMethodIndex: 0,
      spinning: false,
      loading: false,
      disable: false,
      form: {
        cardNumber: '',
        cardFirstName: '',
        cardLastName: '',
        cvv: '',
        expiredDate: '',
        country: '',
        city: '',
        state: '',
        firstName: '',
        lastName: '',
        document: '',
        address: '',
        streetNumber: '',
        zipCode: '',
        email: '',
        phone: '',
        phoneCode: '',
        // 扩展参数
        extension: {}
      },
      formRules: {},
      countdown: 0,
      orderId: this.$route.params.id,
      displayItems: {},
      // 本地支付银行列表
      bankList: [],
      // 本地支付银行关联表单模板列表
      bankTemplateList: []
    }
  },
  async created() {
    try {
      const country = getDefaultCountry(this.orderDetails)
      this.onCountryChange(country)
      this.initTopMethods()
      initFormData(this.form, this.orderDetails)
      initPhoneCode(this, country)
      this.choosedPaymentMethod = this.canSelectMethods[this.choosedPaymentMethodIndex]
      this.initCountryList()
      this.bankList = initBankList(this.choosedPaymentMethod, country)
      initRequiredFields(this, this.choosedPaymentMethod)
    } catch (error) {
      console.error(error)
    }
  },
  mounted() {
    saveExecuteTime('startTime', window.initPage)
    saveExecuteTime('completedTime')
  },
  methods: {
    getSubSelectMethodBackName,
    getCountryNameText,
    initSecondDisplay,
    isShowEmoji,
    onItemFocus() {
      this.operation = {
        code: '',
        type: '',
        focus: new Date().getTime() + '',
        blur: '',
        value: ''
      }
    },
    onItemBlur(code, value, type = 'input') {
      this.operation.value = value
      this.operation.type = type
      this.operation.code = code
      this.operation.blur = new Date().getTime() + ''
      saveOperation(this.operation)
    },
    initTopMethods() {
      initTopMethodList(this)
    },
    initCountryList() {
      initCountryOptions(this)
    },
    filterCountryOption(value, option) {
      return isFilterOption(value, option)
    },
    onCountryChange(country) {
      this.documentLabel = showDocument(country)
      this.canSelectMethods = initCanUseMethod(this, country)
      this.initMethodChild()
      stateControl(this, country)
      documentControl(this, country)
    },
    onBankChange(value) {
      initExtensionRules(this, value)
    },
    expiredDateChangeHandler(evt) {
      this.form.expiredDate = onExpiredDateInput(evt)
    },
    setCountdown(countdown) {
      this.countdown = countdown
    },
    cvvChangeHandler(evt) {
      this.form.cvv = onSecurityCodeInput(evt)
    },
    onInputCardChange(card) {
      // this.disable = !!this.orderDetails?.creditCardLogos.every((item) => item.code !== card)
    },
    submit() {
      saveExecuteTime('subTime')
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.spinning = true
          const submitData = getCheckoutData(this.form, this.canSelectMethods[this.choosedPaymentMethodIndex])
          try {
            const res = await confirm(this.orderId, submitData)
            if (res.status === 301) {
              window.location.href = res.redirectUrl
              return
            }
            toTransactionResult(this, res, this.orderId)
            this.spinning = false
            submitOperationData()
          } catch (error) {
            submitOperationData()
            this.spinning = false
          }
        }
      })
    },
    cancel() {
      window.location.href = this.orderDetails.cancelUrl
    },
    viewAllMethod() {
      this.activePaymentMethodIndex = this.choosedPaymentMethodIndex
      this.viewMorePaymentMethod = true
    },
    initMethodChild() {
      this.form.code = ''
      this.bankList = []
      // eslint-disable-next-line no-unused-expressions
      this.$refs.formRef?.clearValidate()
    },
    chooseMethod(index, methodItem) {
      this.choosedPaymentMethodIndex = index
      this.activePaymentMethodIndex = index
      this.viewMorePaymentMethod = false
      this.choosedPaymentMethod = this.canSelectMethods[this.choosedPaymentMethodIndex]
      if (this.topMethodsIndex.indexOf(this.activePaymentMethodIndex) < 0) {
        this.topMethodsIndex[this.topMethodsIndex.length - 1] = this.activePaymentMethodIndex
        this.topMethods[this.topMethods.length - 1] = this.canSelectMethods[this.activePaymentMethodIndex]
      }
      this.initMethodChild()
      initRequiredFields(this, methodItem)
      this.bankList = initBankList(methodItem, this.form.country)
    },
    handleCancel(e) {
      this.viewMorePaymentMethod = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';
@import '~@/views/checkout/styles/index.less';

.mobile {
  padding: 15px 25px 0;
  background-color: @checkout-bg;

  .warn-tips {
    color: @warn-color;
    margin-top: 10px;
    margin-bottom: -15px;
  }

  .title-lang {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .title {
    margin-top: 10px;
    #Font.heavy();
    font-size: 20px;
    line-height: 27px;
  }

  .description {
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
  }

  .title-logo {
    max-width: 220px;
    max-height: 60px;
    object-fit: cover;
  }

  .more-methods {
    padding: 0;
    #Font.heavy();
    font-size: 14px;
    margin-top: 10px;
    height: 19px;
  }

  .form-wrapper {
    background-color: @white;
    border-radius: 6px 0px 0px 6px;
    margin-top: 25px;

    .countdown-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px;
      border-bottom: 1px solid @checkout-bg;

      .cards {
        img {
          height: 20px;
        }
      }

      .local {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          height: 20px;
        }
      }

      .countdown {
        .time {
          #Font.heavy();
          display: inline-block;
          text-align: center;
          font-size: 14px;
          background: @checkout-bg;
          border-radius: 2px;
          width: 28px;
          height: 23.5px;
          line-height: 23.5px;
        }
      }
    }

    .order-info {
      padding: 13.5px 15px 20px;

      .order-info-content {
        background-color: @checkout-bg;
        border-radius: 5px;
        padding: 13px 10px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 16.5px;
          font-size: 12px;
          margin-top: 9px;

          &:first-child {
            margin-top: 0px;
          }

          .label {
            #Font.regular();
          }

          .text {
            #Font.heavy();

            &.amount {
              font-size: 15px;
              line-height: 20.5px;
            }
          }
        }
      }
    }

    .payment-info {
      background-color: @white;
      padding: 13.5px 15px 0;

      .cvv-img {
        width: 38px;
      }

      .expired-date-img {
        width: 38px;
      }

      .card-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .card-logos {
          display: flex;
          gap: 5px;

          .card-logo {
            height: 20px;
          }
        }
      }
    }

    .billing-info {
      padding: 0 15px 5px;
      color: @checkout-text-color;

      .billing-title {
        #Font.heavy();
        font-size: 14px;
        line-height: 19px;
        padding-bottom: 15px;
      }
    }
  }

  .btns {
    padding: 16px 0px 160px;
    text-align: center;

    .button {
      width: 100%;
      #Font.heavy();
      font-size: 16px;
    }

    .return-link {
      padding: 0;
      #Font.heavy();
      font-size: 16px;
      margin-top: 20px;
    }
  }
}

.ant-drawer {
  ::v-deep(.ant-drawer-header) {
    border-bottom: 0;
    position: fixed;
    width: 100%;

    .ant-drawer-title {
      display: flex;
      justify-content: flex-end;

      .close {
        padding: 0;
        #Font.heavy();
        font-size: 14px;
        line-height: 18px;
        height: 18px;
      }
    }
  }

  ::v-deep(.ant-drawer-body) {
    padding-top: 74px;
  }

  .items {
    .item {
      border-radius: 4px;
      height: 65px;
      display: flex;
      margin-top: 7px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      cursor: default;

      &:first-child {
        margin-top: 0;
      }

      &.active {
        background: #f1f5f7;
      }

      .method-img {
        height: 33.5px;
        margin-right: 28px;
      }

      .method-name {
        #Font.heavy();
        font-size: 18px;
        line-height: 25px;
        color: @checkout-text-color;
      }
    }
  }
}
</style>
