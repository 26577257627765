<template>
  <a-spin :spinning="spinning">
    <div class="qr-code-wrapper">
      <div class="qr-code-content">
        <div class="countdown-wrapper interval">
          <div class="cards">
            <div class="tip">{{ $t('message.checkout.timeLimit') }}</div>
            <div class="countdown">
              <span class="time">{{ initSecondDisplay(Math.floor(countdown / 60)) }}</span> :
              <span class="time">{{ initSecondDisplay(countdown % 60) }}</span>
            </div>
          </div>
        </div>

        <div class="layout-two-side-center interval">
          <div class="qr-code-cards qr-order-info">
            <div class="item">
              <span>{{ $t('message.checkout.orderAmount') }}</span>
              <span class="text">{{ orderDetails?.amount }} {{ orderDetails?.currency }} </span>
            </div>
          </div>
          <div class="qr-code-cards qr-order-info">
            <div class="item">
              <span>{{ $t('message.checkout.orderId') }}</span>
              <span class="text">{{ orderDetails?.uniqueId }}</span>
            </div>
          </div>
        </div>

        <div class="interval">
          <div class="qr-code-cards scan-region">
            <img class="logo" :src="`data:image/png;base64,${orderDetails?.logo}`" alt="logo" />
            <span v-if="!isExpiration">{{ orderDetails?.message }}</span>
            <span v-else class="text-tip">{{ expirationMessage }}</span>
            <div class="canvas-wrapper">
              <img id="codeImg" v-show="!isExpiration" alt="qr code" />
              <div v-if="isExpiration" class="cover-up layout-all-center">
                <a-spin size="large" :spinning="isExpiration"></a-spin>
              </div>
            </div>
            <div class="scan-region-width" v-if="!isExpiration">
              <a-divider>ou</a-divider>
              <a-button type="primary" block size="large" @click="copyQRCode">
                {{ 'Copiar código qr' }}
              </a-button>
              <a-button type="link" @click="onRefreshQrCode" block size="large">
                {{ 'Atualizar código qr' }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { initSecondDisplay, toTransactionResult } from '@/views/checkout/tools'
import { getQrCodeInfo, getQrCodeResult, getRefreshQrCode } from '@/views/checkout/api/qr-code'
import { loadLanguageAsync } from '@/i18n'
import qrCode from 'qrcode'

export default {
  data() {
    return {
      spinning: true,
      orderDetails: null,
      orderId: this.$route.params.id,
      isExpiration: false,
      expirationMessage: '',
      countdown: 0,
      polling: null
    }
  },
  methods: {
    initSecondDisplay,
    makeQrCode: function (code, callback) {
      // 生成二维码
      const opts = {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        quality: 0.3,
        width: 280,
        height: 280,
        margin: 2
      }
      const codeImg = document.getElementById('codeImg')
      codeImg.onload = function () {
        if (callback) {
          callback()
        }
      }
      qrCode.toDataURL(code || '', opts, function (err, url) {
        if (err) throw err
        codeImg.src = url
      })
    },
    setQrCodeExpiration: function (res) {
      // 二维码过期
      this.isExpiration = res?.status === 999
      this.expirationMessage = res?.message
    },
    queryResult: function () {
      this.polling = setInterval(() => {
        getQrCodeResult(this.orderId).then((res) => {
          toTransactionResult(this, res, this.orderId, true)
          this.setQrCodeExpiration(res)
        })
      }, 3000)
    },
    onRefreshQrCode: function () {
      this.isExpiration = true
      getRefreshQrCode(this.orderId).then((res) => {
        this.makeQrCode(res?.qrCode, () => {
          this.isExpiration = false
        })
      })
    },
    clipboardImg: async function (url) {
      try {
        const data = await fetch(url)
        const blob = await data.blob()
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob
          })
        ])
        this.$message.success('Replicar com sucesso')
      } catch (err) {
        this.$message.success('Falha na cópia')
      }
    },
    copyQRCode: function () {
      const codeImg = document.getElementById('codeImg')
      const url = codeImg.src
      if (url) {
        var canvas = document.createElement('canvas')
        const image = new Image()
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = url
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          canvas.getContext('2d').drawImage(image, 0, 0)
          const url = canvas.toDataURL('image/png')
          this.clipboardImg(url)
        }
      } else {
        this.$message.error('Falha na cópia')
      }
    }
  },
  mounted() {
    loadLanguageAsync('pt')
    getQrCodeInfo(this.orderId)
      .then((res) => {
        this.orderDetails = res
        this.setQrCodeExpiration(res)
        if (!this.isExpiration) {
          toTransactionResult(this, res, this.orderId, true)
          this.countdown = this.orderDetails?.expire || 0
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1
            } else {
              clearInterval(timer)
            }
          }, 1000)
          // 轮询查询订单状态
          const count = setTimeout(() => {
            this.queryResult()
            clearTimeout(count)
          }, 5000)
          // 生成二维码
          this.makeQrCode(this.orderDetails?.qrCode)
        } else {
          this.onRefreshQrCode()
        }
        this.spinning = false
      })
      .catch(() => {
        this.spinning = false
      })
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common.less';

.qr-code-wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;

  .qr-code-content {
    width: 900px;
    text-align: center;
    margin: 20px;
    border-radius: 6px;

    .qr-code-cards {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: @white;
      padding: 15px;
    }

    .interval {
      padding: 20px 15px 0;
      &:last-child {
        padding-bottom: 20px;
      }
    }

    .countdown-wrapper {
      display: flex;
      align-items: center;

      .cards {
        background-color: @bg-color3;
        border-radius: 6px;
        padding: 13px 10px;
        width: 100%;
        height: 100%;

        .tip {
          color: @white;
          #Font.regular();
          font-size: 14px;
          line-height: 19px;
          padding: 5px;
          margin-bottom: 10px;
        }

        .countdown {
          .time {
            #Font.heavy();
            display: inline-block;
            text-align: center;
            font-size: 14px;
            background: @checkout-bg;
            border-radius: 4px;
            width: 40px;
            height: 34px;
            line-height: 34px;
          }
        }
      }
    }

    .qr-order-info {
      width: 48%;

      .item {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .text {
          font-weight: bold;
        }
      }
    }

    .scan-region {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-weight: bold;

      .logo {
        margin-top: 5px;
        height: 20px;
        margin-bottom: 15px;
      }

      .text-tip {
        color: @checkout-error-color;
      }

      .canvas-wrapper {
        background: @white;
        min-width: 302px;
        height: 302px;
        padding: 10px;
        border-radius: 6px;
        margin-top: 15px;
        border: 1px solid @border-color;
        position: relative;

        .cover-up {
          position: absolute;
          margin: 15px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: hsla(0, 10%, 80%, 0.5);
          z-index: 100;
          -webkit-filter: blur(3px);
          -moz-filter: blur(3px);
          -ms-filter: blur(3px);
          filter: blur(3px);
          color: @white;
        }
      }
    }
    .scan-region-width {
      width: 60%;
      min-width: 302px;
      margin: auto;
    }
  }
}

@media screen and(max-width: 900px) {
  .qr-code-wrapper {
    .qr-code-content {
      width: 100%;
    }
  }
}
</style>
