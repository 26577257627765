import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '../layout/base/index.vue'
import CheckoutLayout from '../layout/checkout/index.vue'
import SDKCheckoutLayout from '../layout/sdk/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dispute',
    name: 'Base',
    component: BaseLayout,
    children: [
      {
        path: 'apply',
        name: 'DisputeApply',
        component: () => import('@/views/dispute/apply/index.vue')
      },
      {
        path: 'chat',
        name: 'DisputeChat',
        component: () => import('@/views/dispute/chat/index.vue')
      }
    ]
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutLayout,
    children: [
      {
        path: ':id',
        name: 'CheckoutPayment',
        component: () => import('@/views/checkout/payment/index.vue'),
        meta: { title: 'Checkout' }
      },
      {
        path: 'qr-code/:id',
        name: 'CheckoutQrCode',
        component: () => import('@/views/checkout/payment/qr-code/index.vue'),
        meta: { title: 'Checkout' }
      },
      {
        path: 'pending/:id',
        name: 'CheckoutPending',
        component: () => import('@/views/checkout/result/index.vue'),
        meta: { title: 'Checkout' }
      },
      {
        path: 'success/:id',
        name: 'CheckoutSuccess',
        component: () => import('@/views/checkout/result/index.vue'),
        meta: { title: 'Checkout' }
      },
      {
        path: 'failed/:id',
        name: 'CheckoutFailed',
        component: () => import('@/views/checkout/result/index.vue'),
        meta: { title: 'Checkout' }
      }
    ]
  },
  {
    path: '/sdk-checkout',
    name: 'SDK Checkout',
    component: SDKCheckoutLayout,
    children: [
      {
        path: 'number/:id',
        name: 'SDK Checkout Number',
        component: () => import('@/views/sdk/card-number/index.vue'),
        meta: { title: 'SDK Number' }
      },
      {
        path: 'date/:id',
        name: 'SDK Checkout Date',
        component: () => import('@/views/sdk/expiration-date/index.vue'),
        meta: { title: 'SDK Date' }
      },
      {
        path: 'code/:id',
        name: 'SDK Checkout CVV',
        component: () => import('@/views/sdk/security-code/index.vue'),
        meta: { title: 'SDK CVV' }
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'Service Platform'
  }
  next()
})

export default router
